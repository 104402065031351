import { RouteName } from "routes/routes.types";
import { UserAreaName } from "types/user.types";

type AreaRoutesMap = Record<UserAreaName, RouteName[]>;

export const areaRoutesMap: AreaRoutesMap = {
    Agreements: ["agreements"],
    AGV_AlarmOverview: [],
    Chameleon: ["chameleon"],
    ENG_ConnectionPerformance: ["connectionPerformance"],
    ENG_LinerAdvice: ["TDSLinerAdvice"],
    ENG_MilkFlowProfile: ["mfpBimodality", "mfpPlotter"],
    FMS_Beta: ["farmScan"],
    FMS_Core: [
        "criticalAlarmsAnalysis",
        "customerDetail",
        "customers",
        "dashboard",
        "farmScan",
        "horizonUsage",
        "vectorLoadingPerformance",
    ],
    FMS_CareCheck: [],
    FMS_Dev: [],
    LIOBS_Core: [],
    ServiceManager_Core: [
        "attentionCustomers",
        "customerDetail",
        "dashboard",
        "devices",
        "customerDeviceDetail",
        "customerFarmCompressors",
        "profitability",
        "profitabilityCriticalAgreements",
        "serviceAreaSupport",
        "strategicOverview",
        "technicianDetail",
        "technicianDetailAbnormalPMTimes",
        "technicianDetailLongRepairs",
        "technicianDetailRepeatVisitRate",
    ],
    TSS_Core: [
        "customerDetail",
        "customerDeviceDetail",
        "customerDeviceReport",
        "customerFarmCompressors",
        "dashboard",
    ],
    TSS_MandatoryUpdates: ["mandatoryUpdates"],
    TSS_Parts: ["partDetail", "parts"],
    TSS_PreventiveMaintenanceOverdue: ["preventiveMaintenanceOverdue"],
    TSS_RepairsPerAstronaut: ["repairsPerAstronaut"],
    TSS_RepeatVisitCases: ["repeatVisitCases"],
    TSS_Technician_Core: ["dashboard"],
    TSS_TechnicianDetail: [
        "technicianDetail",
        "technicianDetailAbnormalPMTimes",
        "technicianDetailLongRepairs",
        "technicianDetailRepeatVisitRate",
        "technicians",
    ],
    TSS_TeamLead_Core: [
        "abnormalPreventiveMaintenanceTimes",
        "averageRepairTimes",
        "criticalAlarmsAnalysis",
        "longRepairs",
        "manHoursHigh",
        "productiveTime",
        "softwareVersionOverview",
    ],
    TSS_TeamLeadKPI: ["dashboard"],
};

export const getAreaRoutes = (areas: UserAreaName[]): RouteName[] => {
    return areas.flatMap((area) => areaRoutesMap[area]);
};
